export abstract class ErrorCode {
  /*
   * 1x : General
   * 2x : Authentication / Permission
   * 3x : Form errors
   * 4x : Unknown
   */
  public static readonly ERROR_CODES = {
    ERR_API_UNAVAILABLE: 10, // Api unavailable error
    ERR_INTERNAL: 11, // Internal server error
    ERR_MAINTENANCE_MODE: 12, // Maintenance error
    ERR_AUTH_FAILURE: 20, // Authentication failure
    ERR_FORBIDDEN: 21, // Access denied (role or permission)
    ERR_NOT_FOUND: 22, // Not found
    ERR_FORM: 30, // Forms errors
    ERR_UNKNOWN: 40 // Unknown error
  };

  public static httpToErrorCode(code: number) {
    switch (code) {
      case 0: return this.ERROR_CODES.ERR_API_UNAVAILABLE;
      case 401: return this.ERROR_CODES.ERR_AUTH_FAILURE;
      case 403: return this.ERROR_CODES.ERR_FORBIDDEN;
      case 404: return this.ERROR_CODES.ERR_NOT_FOUND;
      case 422: return this.ERROR_CODES.ERR_FORM;
      case 500: return this.ERROR_CODES.ERR_INTERNAL;
      case 503: return this.ERROR_CODES.ERR_MAINTENANCE_MODE;
      default: return this.ERROR_CODES.ERR_UNKNOWN;
    }
  }
}
