<div *ngIf="!(auth0Enabled | async)" style="display: flex; flex-direction: column; gap:16px" class="sign-in-up-component">
  <div class="mb-2rem">
    <h2 class="h1 text-center sp-title">Everything starts from here!</h2>
    <h3 class="h5 text-center sp-text-secondary sp-baseline">Log in to access your account</h3>
  </div>
  <div class="card p-4" (keydown)="keyDownFunction($event)">
    <div *ngIf="!router.url.includes('user/login?state=registered')" style="display: flex; flex-direction: column; gap:8px" >
      <mat-form-field appearance="outline" class="sp-form-field" style="flex-grow: 1">
        <mat-label class="sp-input-label">Email Address</mat-label>
        <input [formControl]="emailControl" autofocus matInput placeholder="Type your email address..." required value=""/>
        <mat-error *ngIf="emailControl.hasError('required')">
          Required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="sp-form-field" style="flex-grow: 1">
        <mat-label class="sp-input-label">Password</mat-label>
        <input [formControl]="passwordControl" [type]="hide ? 'password' : 'text'" matInput placeholder="Type your password..." required value=""/>
        <mat-error  *ngIf="passwordControl.hasError('required')">
          Required
        </mat-error>
      </mat-form-field>
      <div style="display: flex; flex-direction: column; gap:4px">
        <div style="display:flex; justify-content: end">
          <a href="/user/password" matTooltip="Click here to recover your password" matTooltipHideDelay="10">Password forgotten?</a>
        </div>
        <button (click)="onClassicLogin()" [disabled]="!isSubmitEnabled()" class="btn btn-lg btn-primary sp-btn sp-btn-lg sp-btn-primary" matTooltip="Click here to sign in" matTooltipHideDelay="10" type="button" >
          <span>Sign In</span>
        </button>
      </div>
    </div>
    <div *ngIf="router.url.includes('user/login?state=registered')">
      <div class="sp-text-secondary text-center m-3">
        <h4>Your account has been created successfully! <br></h4>
        <h4>Check your mails to finalize your inscription. </h4>
      </div>
      <img [src]="'assets/images/socio-demo/single.png'" class="sp-single-image">
      <img [src]="'assets/images/socio-demo/parents.png'" class="sp-parents-image">
      <img [src]="'assets/images/socio-demo/multiParents.png'" class="sp-relation-image">
    </div>
  </div>
</div>
<div *ngIf="auth0Enabled | async" style="display: flex; flex-direction: column; gap:48px; justify-self:center" class="sign-in-up-component">
  <div class="flex flex-col gap-16 items-center" style="justify-content: center">
    <mat-icon style="height: 48px; width: 48px" svgIcon="icon_sp_rocket">icon_sp_rocket</mat-icon>
    <div class="font-normal text-4xl">Your Soprism login has changed!</div>
  </div>
  <div class="flex flex-col gap-40">
    <div class="text-center">
      We’re simplifying your login experience across our tools with Single Sign-On (SSO) using Auth0:
    </div>
    <div class="flex flex-col gap-24">
      <div class="text-center flex flex-col gap-16">
        <div><strong>Already have access to Audiense Insights?</strong></div>
        <div>These credentials will become your single login for both tools.</div>
      </div>
      <divider></divider>
      <div class="text-center flex flex-col gap-16">
        <div><strong>Only use Soprism?</strong></div>
        <div> Simply use your current credentials on the new login page.</div>
      </div>
      <divider></divider>
      <div class="text-center flex flex-col gap-16">
        <div><strong>Have Questions?</strong></div>
        <div>Contact our support team at <a href="mailto:help@audiense.com">help@audiense.com</a> or check your email for more details.</div>
      </div>
      <divider></divider>
    </div>
  </div>
  <div class="flex flex-col gap-16">
    <button [disabled]="waiting" (click)="onSsoLogin()" class="btn btn-lg btn-primary sp-btn sp-btn-lg sp-btn-primary" matTooltip="Click here to sign in"  matTooltipHideDelay="10" type="button" >
      <span>Sign In with Audiense</span>
    </button>
  </div>
</div>
<p-messages [(value)]="msgs"></p-messages>
