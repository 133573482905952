import { NgModule } from '@angular/core';
import { SpAdminCompanyCreateComponent } from './sp-admin-company-create/sp-admin-company-create.component';
import { SpAdminCompanyListComponent } from './sp-admin-company-list/sp-admin-company-list.component';
import { SpAdminCompanyRoutingModule } from './sp-admin-company-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSortModule} from "@angular/material/sort";
import {MessagesModule} from 'primeng/messages';
import {FilterPipe} from './sp-admin-company-create/sp-modal-company-add-users/filter.pipe';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {SpAdminCompanyUsersComponent} from './sp-admin-company-users/sp-admin-company-users.component';


@NgModule({
  declarations: [
    SpAdminCompanyCreateComponent,
    SpAdminCompanyListComponent,
    FilterPipe
  ],
  exports: [
    SpAdminCompanyCreateComponent,
    FilterPipe,

  ],
  imports: [
    SpAdminCompanyRoutingModule,
    MatButtonModule,
    MatFormFieldModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    CommonModule,
    ReactiveFormsModule,
    MatSortModule,
    FormsModule,
    MessagesModule,
    MatTooltipModule,
    MatAutocompleteModule,
    SpAdminCompanyUsersComponent
  ]
})
export class SpAdminCompanyModule {
}
