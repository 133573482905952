import {Injectable} from '@angular/core';
import {User} from "../../models/user";
import {firstValueFrom, Subject} from "rxjs";
import {Session} from "../../models/session";
import {ApiService} from "../sp-api/api.service";
import {environment, environment as env} from "../../../environments/environment";
import {LoggerService} from '../sp-logger/logger.service';
import {SocketService} from "../sp-ws/socket.service";
import {Analytics} from '@segment/analytics-node';
import {AuthGuardian} from '@audienseco/auth-guardian';
import {Router} from '@angular/router';
import {ApiUserService} from '../sp-api/sp-api-user/api-user.service';
import {FeatureFlagsService} from '../feature-flag.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private analytics: Analytics = new Analytics({writeKey: 'CZMrKZHIV4YEY6Kd7bQr1QhDRPmocR3L'});
  private _redirectUrl: string = window.location.protocol + '//' + window.location.host + '/oauth/callback';
  private logOutRedirectUrl: string = window.location.protocol + '//' + window.location.host;

  private readonly authGuardian?: AuthGuardian;

  constructor(private api: ApiService,
              private userApi: ApiUserService,
              private logger: LoggerService,
              private socket: SocketService,
              private router: Router,
              private _session: Session,
              private featureFlagService: FeatureFlagsService
              ) {
    if (env.config.auth0) {
      this.authGuardian = new AuthGuardian({
        domain: env.config.auth0.domain,
        client_id: env.config.auth0.clientId,
        redirect_uri: this._redirectUrl,
        audience: env.config.auth0.audience
      });
    }
  }

  /**
   * API Authentication
   * Send token request to the API with provided email and password
   * @param email User email
   * @param password User password
   */
  async classicLogin(email: string, password: string) {
    try {
      const login = await firstValueFrom(this.api.post<any>("auth", { email, password }));
      const token = login.token;
      const socketToken = login.socket;
      this._session.storeAuthInfo(token, socketToken);
      this.socket.close();
      await this.userInit();
    } catch (error) {
        this._session.newSessionWithError(error);
    }
  }

  private ssoCheck() {
    if (!this.auth0Enabled()) throw new Error('SSO (Auth0) is not enabled');
  }

  async ssoLogin(state?: {[key: string]: any}) {
    this.ssoCheck();
    await this.authGuardian.loginWithRedirect({appState: {fromUrl: location.pathname, ...state}});
  }

  async ssoHandleRedirect() {
    this.ssoCheck();
    return await this.authGuardian.handleRedirect();
  }

  async ssoRequestToken() {
    this.ssoCheck();
    const token = await this.authGuardian.getAccessToken();
    this._session.storeAuthInfo(token);
    try {
      const socketToken = await this.userApi.getUserSocketToken();
      this._session.storeAuthInfo(token, socketToken.token);
      this.socket.close();
      await this.userInit();
    } catch (err) {
      this.session.newSessionWithError(err);
    }
  }

  /**
   * One-Time Password login by giving proof token
   * @param token
   */
  loginOtp(token: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.api.post<any>("auth/otp", { token }).subscribe(loginOtp => {
        const token = loginOtp.token;
        const socketToken = loginOtp.socket;
        this.logger.debug("Received token from OTP : " + token);
        this._session.storeAuthInfo(token, socketToken);

        this.userInit().then();
        resolve(true);
      }, error => {
        reject(error);
        this._session.newSessionWithError(error);
      })
    });
  }

  loginImpersonate(user: User|{id: number}): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.api.post<any>("auth/impersonate/" + user.id).subscribe(async response => {
        this._session.storeAuthInfo(response.token);
        await this.userInit();
        resolve(true);
      }, error => {
        reject(error);
      });
    });
  }

  async logout() {
    this._session.sessionClear();
    if (await this.auth0Enabled()) this.authGuardian.logout({url: this.logOutRedirectUrl}).then();
    else await this.router.navigate(['user', 'login']);
  }

  register(user: User) {
    return firstValueFrom(this.api.post<User>("register", user));
  }

  registerFromInvite(token: string, user: User) {
    let openUser: any = user;
    openUser.token = token;

    return this.api.post('register/validate', openUser);
  }

  registerConfirm(token: string) {
    return this.api.post('register/confirm', {token});
  }

  requestPasswordReset(email: string, captcha: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.api.post<any>("password/forgotten", {email, captcha})
        .subscribe(() => resolve(true), error => reject(error));
    });
  }

  async redirectToLogin() {
    if (await this.auth0Enabled()) {
      //await this.ssoLogin();
      await this.router.navigate(['user', 'login']);
    } else {
      await this.router.navigate(['user', 'login']);
    }
  }

  /**
   * User initialization client-side by asking API information
   */
  async userInit() {
    const user = await firstValueFrom(this.api.get<User>("user"));
    this._session.newSessionWithUser(user);
    this.logger.logInfo("Session user : " + user.first_name + " " + user.last_name + " [ID: " + user.id + ", FC: " + user.first_connection + "]")
    if (environment.config.production) {
      const subPlan = user.company.subscription.plan;
      const subPlanName = (subPlan.custom ? "Custom plan" : subPlan.name).toLowerCase().replace(" ", "_");
      this.analytics.identify({
        userId: user.id.toString(),
        traits: {
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          soprism_plans: subPlanName
        }
      });
    }
    return user;
  }

  /**
   * Session loading
   * Load a stored session by retrieving stored token if any
   * If no token is provided, the user will be redirected to login page
   */
  public async sessionLoad() {
    const token = this._session.authToken;
    this.logger.logInfo("Loading session...");

    // Token verification
    if(token !== null) {
      this.logger.logInfo("Token detected, getting user info...");
      try {
        await this.userInit();
      } catch (error) {
        this.session.newSessionWithError(error);
      }
    } else {
      this.logger.logInfo("No token detected, initializing as guest");
      this._session.initialize();
    }
  }

  /**
   * Permission checking
   * Check permission from API permission system
   * @param permission
   */
  public permissionCheck(permission: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const checkPerm = (session: Session) => {
        if(!environment.config.permCheck) resolve(true);
        if (!session.authenticated || !session.user) resolve(false);

        let permissions = session.user.permissions;
        let keys = permissions.map(p => p.key);

        if(!keys.includes(permission)) {
          resolve(keys.includes('*'));
        } else {
          let basePerm = permissions.find(perm => perm.key == permission);
          resolve(basePerm.access);
        }
      };

      if (this._session.initialized) {
        checkPerm(this._session);
      } else {
        const sub = this._session.sessionListener.subscribe(session => {
          if (!session.failed) {
            checkPerm(session);
          } else {
            this.logger.logError("Permission check with failed session");
            resolve(false);
          }

          sub.unsubscribe();
        })
      }
    });
  }

  get session(): Session {
    return this._session;
  }

  get sessionListener(): Subject<Session> {
    return this._session.sessionListener;
  }

  async auth0Enabled(){
    return await this.featureFlagService.isEnabledPromise('auth0');
  }

  auth0EnabledObservable(){
    return this.featureFlagService.isEnabledObservable('auth0');
  }
}
