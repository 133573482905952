import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { SpLayoutDashboardDeskComponent } from './sp-layout-dashboard-desk.component';
import {SliderModule} from 'primeng/slider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {SpToolsModule} from '../../sp-tools/sp-tools-module';
import {SpUserModule} from '../../sp-user/sp-user.module';
import {NotifierModule} from 'angular-notifier';
import {ToastrModule} from "ngx-toastr";
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatExpansionModule} from '@angular/material/expansion';
import {FilterDrawerComponent} from '../../../filter-drawer/filter-drawer.component';
import {FilterPipe} from '../../../pipes/filter.pipe';
import {TitanDivider} from '../../../user-interface/titan-divider/titan-divider';

@NgModule({
  declarations: [SpLayoutDashboardDeskComponent],
  exports: [
    SpLayoutDashboardDeskComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    SliderModule,
    MatTooltipModule,
    SpToolsModule,
    SpUserModule,
    MatChipsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatInputModule,
    NotifierModule.withConfig({
      theme: 'material',
      position: {
        horizontal: {
          position: 'right'
        },
        vertical: {
          position: 'top'
        }
      }
    }),
    ToastrModule.forRoot({
      progressBar: true,
      maxOpened: 3,
      newestOnTop: true,
      closeButton: true
    }),
    MatTabsModule,
    DragDropModule,
    MatSidenavModule,
    MatExpansionModule,
    FilterDrawerComponent,
    FilterPipe,
    TitanDivider,
  ]
})
export class SpLayoutDashboardDeskModule {

}
