import {Injectable} from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {Observable, throwError} from 'rxjs';
import {catchError, map, switchMap, timeout} from 'rxjs/operators';
import {Router} from "@angular/router";
import {AuthenticationService} from "../services/sp-authentication/authentication.service";
import {environment} from "../../environments/environment";
import {InterruptError} from "../errors/interrupt-error";
import {AlertService} from '../services/sp-alert/alert.service';
import {LoggerService} from '../services/sp-logger/logger.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private router: Router, private auth: AuthenticationService, private alert: AlertService, private logger: LoggerService) { }

  /**
   * API Interceptor for errors
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!environment.config.apiCheck) return next.handle(req).pipe(timeout(120000));

    const token = this.auth.session.authToken;
    let refreshAttempted: boolean = false;

    if (token && !req.headers.has('Authorization')) {
      req = req.clone({
        headers: req.headers
          .set("Authorization", "Bearer " + token)
          .set("Accept", "application/json")
          //.set("Content-Type", "application/json")
      })
    }

    return next.handle(req).pipe(
      map ((event: HttpEvent<any>) => {
        let data = event;
        if (event instanceof HttpResponse) {
          /* Handle successful responses here */

          if(event.body && event.body.message && event.body.message.type) {
            const type = event.body.message.type;
            const content = event.body.message.content

            switch (type) {
              case "info": this.alert.notify("Info", content, "info"); break; // Info notification
              case "success": this.alert.notify("Success", content, "success"); break; // Success notification
              case "warning": this.alert.notify("Warning", content, "warning"); break; // Warning notification
              case "error": this.alert.notify("Error", content, "error"); break; // Error notification
              default: {
                if(!environment.config.production && !environment.config.demo) {
                  this.alert.notify("Unknown message", "Unknown message type '" + event.body.message.type + "' from Core API", "warning");
                }
              }
            }

            if(event.body.message.interrupt) {
              this.logger.logError(event.body);
              throw new InterruptError(event.body.message.content);
            }
          }

          /* -------------------------------------------------- */
        }

        return data;
      }),
      catchError ((response: Error) => {
        if (response instanceof HttpErrorResponse) {
          /* Handle errors here */
          if (environment.config.apiErrorCheck && (response.url.includes(environment.config.api.baseUrl) || response.url.includes(environment.config.api.statsUrl))) {
            let status = response.status;
            if (status === 0) {
              const chunkFailedMessage = /Loading chunk [\d]+ failed/;

              if (chunkFailedMessage.test(response.message)) {
                window.location.reload();
              }
            }
            if (status >= 400 && status < 500) {
              if (response.error !== undefined) this.logger.logError(response.error);
              else this.logger.logError(response.message);
              if (status === 401) {
                this.auth.session.newSessionWithError(response);
              }
            } else if (status >= 500) {
              if (status === 500) {
                if (!environment.online) {
                  this.logger.logError(response.error);
                  if (environment.config.showErrorModal) this.alert.notify("Error", response.error.message, "error");
                }
              }
              if (status === 503) {
                this.router.navigateByUrl("/page-maintenance").then();
              }
              if (status === 504) {
                this.logger.logWarning("504 Timeout received");
              }
            }
          }
          /* -------------------------------------------------- */

          return throwError(() => response);
        }
      })
    );
  }
}
