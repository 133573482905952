import { Injectable } from '@angular/core';
import {firstValueFrom, Observable, of, map, catchError } from 'rxjs';
import {ApiService} from './sp-api/api.service';

export interface FeatureFlag {
  id: number;
  key: string;
  global_enabled: boolean;
  user_enabled: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService extends ApiService {
  private apiUrl = 'feature_flags';

  async getFeatureFlags(): Promise<FeatureFlag[]> {
    return firstValueFrom(this.get<FeatureFlag[]>(this.apiUrl));
  }

  updateFeatureFlag(flag: FeatureFlag): Observable<FeatureFlag> {
    if (flag.user_enabled !== undefined && flag.user_enabled !== null) localStorage.setItem(flag.key, flag.user_enabled.toString());
    return this.put<FeatureFlag>(this.apiUrl, flag);
  }

  isEnabledObservable(key: string): Observable<boolean> {
    const localFlagValue = localStorage.getItem(key);
    if (localFlagValue) {
      return of(localFlagValue === 'true');
    } else {
      return this.get<boolean>(`${this.apiUrl}/${key}/is_enabled`).pipe(
          map(flag => {
            return flag;
          }),
          catchError(() => of(false))
      );
    }
  }

  async isEnabledPromise(key: string): Promise<boolean> {
    return firstValueFrom(this.isEnabledObservable(key));
  }
}
