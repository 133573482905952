<button (click)="handleButtonClick()"
        [disabled]="isDisabled"
        [ngClass]="{
        'button-circle': buttonType == 'circle',
        'button-primary' : variant == 'primary',
        'button-secondary' : variant == 'secondary',
        'button-information' : variant == 'information'}"
        class="flex items-center border-1 border-radius-8 gap-4 py-6 px-8">
  <ng-content select="[icon]"></ng-content>
  <ng-content select="[children]"></ng-content>
</button>
