<div class="sp-dialog">
  <div class="sp-dialog-header">
    <h5> Add your project to your folder</h5>
  </div>
  <div class="sp-dialog-body">
    <mat-form-field class="w-100 sp-form-field" appearance="outline">
      <mat-label class="sp-input-label">Folders</mat-label>
      <!-- TODO: This is ugly we should do something about that -->
      <input #folderSearchInput (input)="selected = null" [value]="selected ? buildFolderName(selected) : ''" [formControl]="folderInputControl"
              [matAutocomplete]="auto" class="sp-input-content" matInput placeholder="Type folder’s name..."/>
      <mat-autocomplete (optionSelected)="selected = $event.option.value" #auto="matAutocomplete">
        <mat-option *ngFor="let folder of filteredFolders() | FilterPipe: folderSearchInput.value" [value]="folder" >
          {{ buildFolderName(folder) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="pb-3 sp-dialog-footer">
      <button mat-button class="btn btn-secondary m-1 text-uppercase sp-btn sp-btn-secondary" (click)="onNoClick()" >Cancel</button>
      <button *ngIf="!selected" mat-button (click)="createFolder()" class="btn btn-primary m-1 text-uppercase sp-btn sp-btn-primary">Create</button>
      <button *ngIf="selected" mat-button (click)="addToFolder(selected)" class="btn btn-primary m-1 text-uppercase sp-btn sp-btn-primary">Select</button>
    </div>
  </div>
</div>
