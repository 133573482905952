import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SpUserModule} from '../modules/sp-user/sp-user.module';
import {FilterService} from '../services/sp-filter/filter.service';
import {PersonaFilter} from '../classes/filter/persona-filter';
import {SliderModule} from 'primeng/slider';
import {BaseStatFilter, ItemBlacklist} from '../classes/filter/base-stat-filter';
import {LoggerService} from '../services/sp-logger/logger.service';
import {Router} from '@angular/router';
import {NavigationService} from '../services/sp-navigation/navigation.service';
import {MatAutocompleteModule, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {StatItem} from '../models/stats/stat-item';
import {Subscription} from 'rxjs';
import {ProjectManagerService} from '../services/sp-project/project-manager.service';
import {MindsetCategory} from '../models/mindset-category';
import {ThousandSeparatorDirective} from '../directives/thousand-separator.directive';
import {TitanButtonComponent} from '../user-interface/titan-button/titan-button.component';
import {FilterPipe} from '../pipes/filter.pipe';

@Component({
  selector: 'app-filter-drawer',
  templateUrl: './filter-drawer.component.html',
  standalone: true,
  imports: [
    MatTooltipModule,
    NgClass,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    NgForOf,
    ReactiveFormsModule,
    SpUserModule,
    FormsModule,
    NgIf,
    SliderModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    ThousandSeparatorDirective,
    TitanButtonComponent,
    FilterPipe
  ],
  styleUrls: ['./filter-drawer.component.scss']
})
export class FilterDrawerComponent implements OnInit, OnDestroy {
  drawerOpen: boolean = false;
  mindsetsActive = false;
  genderFilter: number = 4; // 1: Male, 2: Female, 3: Both, 4: All
  segmentSelected: string = "";
  searchSegment: string;
  searchMindsetCategory: string;
  targetSize: number [] = [0, 60000000];
  targetSizeMax: number ;
  targetSizeMin: number = 0;
  // Filter thresholds
  criteriaScorableMaxThreshold: number = 200;
  tagScorableMaxThreshold: number = 200;
  rangeValuesPenetrationCriteria: number[] = [5, 100];
  rangeValuesSelectivityCriteria: number[] = [0, 200];
  criteriaBlacklist: ItemBlacklist[] = [];
  // Tags related
  rangeValuesPenetrationTags: number[] = [3, 100];
  rangeValuesSelectivityTags: number[] = [0, 200];
  tagBlacklist: ItemBlacklist[] = [];
  minAge: number = 18;
  maxAge: number = 65;
  availableAgesMin: number[] = [18, 24, 34, 44, 54, 64];
  availableAgesMax: number[] = [ 25, 35, 45, 55, 65];
  private subscriptions: Subscription[] = [];
  selectedMindsetCategories: MindsetCategory[] = [];

  private _tagStat: StatItem[] = [];
  private _criteriaStat: StatItem[] = [];

  @Input() mindsetCategories: MindsetCategory[] = [];

  // Category input ref
  @ViewChild('mindsetCategorySearchInput') mindsetCategorySearchInput: ElementRef<HTMLInputElement>;

  private filter: BaseStatFilter = {
    // Criterion
    criteriaPenetrationMin: this.rangeValuesPenetrationCriteria[0],
    criteriaPenetrationMax: this.rangeValuesPenetrationCriteria[1],
    criteriaSelectivityMin: this.rangeValuesSelectivityCriteria[0],
    criteriaSelectivityMax: this.rangeValuesSelectivityCriteria[1],
    criteriaBlacklist: this.criteriaBlacklist,

    // Tags
    tagPenetrationMin: this.rangeValuesPenetrationTags[0],
    tagPenetrationMax: this.rangeValuesPenetrationTags[1],
    tagScorableMin: this.rangeValuesSelectivityTags[0],
    tagScorableMax: this.rangeValuesSelectivityTags[1],
    tagBlacklist: this.tagBlacklist
  };

  constructor(public filterService: FilterService,
              private logger: LoggerService,
              public router: Router,
              public navigation: NavigationService,
              public projectManager: ProjectManagerService) {}

  ngOnInit(): void {
    this.subscriptions.push(this.filterService.onThresholdChange.subscribe(threshold => {
      switch (threshold.flag) {
        case "criterion":
          this.criteriaScorableMaxThreshold = threshold.value;
          this.rangeValuesSelectivityCriteria = [this.rangeValuesSelectivityCriteria[0], threshold.value];
          this.filter.criteriaSelectivityMax = threshold.value;
          break;
        case "tags":
          this.tagScorableMaxThreshold = threshold.value;
          this.rangeValuesSelectivityTags = [this.rangeValuesPenetrationTags[0], threshold.value];
          this.filter.tagScorableMax = threshold.value;
          break;
        case "segment-min":
          this.targetSizeMin = threshold.value;
          this.targetSize[0] = threshold.value;
          break;
        case "segment-max":
          this.targetSizeMax = threshold.value;
          this.targetSize[1] = threshold.value;
          break;
        default:
          this.logger.logWarning("Threshold for flag " + threshold.flag + " is not supported");
      }
    }));
    this.subscriptions.push(this.projectManager.projectDataLoadedListener.subscribe(data => {
      this._criteriaStat = data.get('criterion');
      this._tagStat = data.get('tags');
    }));
    this.updateAgeOptions();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /* Set the width of the side navigation to 250px */
  openNav() {
    this.drawerOpen = true;
    if(this.drawerOpen) {
      const elem =  document.getElementById("mySidenav");
      if (elem) {
        elem.style.width = '550px';
        document.getElementById("overlay").classList.add("active");
      }
    } else {
      this.closeNav();
    }
  }

  /* Set the width of the side navigation to 0 */
  closeNav() {
    this.drawerOpen = false;
    const elem =  document.getElementById("mySidenav");

    if(elem ) {
      elem.style.width = '0';
      document.getElementById("overlay").classList.remove("active");
    }
  }

  updateGender(gender: number) {
    if ((gender == 1 && this.genderFilter == 2) || (gender == 2 && this.genderFilter == 1)) {
      this.genderFilter = 3;
    } else if (gender == 1 && this.genderFilter == 3) {
      this.genderFilter = 2;
    } else if (gender == 2 && this.genderFilter == 3) {
      this.genderFilter = 1;
    } else {
      this.genderFilter = gender;
    }
    this.mindsetsActive = false;
  }

  updateAgeOptions() {
    // Ensure minAge is not greater than maxAge
    if (this.minAge >= this.maxAge) {
      this.minAge = this.availableAgesMin.filter(age => age < this.maxAge).pop() || this.availableAgesMin[0];
    }
    // Ensure maxAge is not less than minAge
    if (this.maxAge <= this.minAge) {
      this.maxAge = this.availableAgesMax.find(age => age > this.minAge) || this.availableAgesMax[this.availableAgesMax.length - 1];
    }
  }

  isMinAgeDisabled(age: number): boolean {
    const nextValidMaxAge = this.availableAgesMax.find(maxAge => maxAge > age || maxAge > age-1);
    return age + 1 >= this.maxAge || (nextValidMaxAge !== undefined && age >= nextValidMaxAge);
  }

  isMaxAgeDisabled(age: number): boolean {
    const prevValidMinAge = [...this.availableAgesMin].reverse().find(minAge => minAge < age);
    return age - 1 <= this.minAge || (prevValidMinAge !== undefined && age <= prevValidMinAge);
  }

  applyPersonaFilter() {
    const filter: PersonaFilter = {
      targetMin: this.targetSizeMin,
      targetMax: this.targetSizeMax,
      ageMin: this.minAge,
      ageMax: this.maxAge,
      gender: this.genderFilter,
      segment: this.segmentSelected,
      mindsets: this.mindsetsActive,
      mindsetCategories: this.selectedMindsetCategories
    };

    this.filterService.personaFilterRequest(filter);
    this.closeNav();
  }

  applyBaseStatFilter() {
    this.filter = {
      // Criterion
      criteriaPenetrationMin: this.rangeValuesPenetrationCriteria[0],
      criteriaPenetrationMax: this.rangeValuesPenetrationCriteria[1],
      criteriaSelectivityMin: this.rangeValuesSelectivityCriteria[0],
      criteriaSelectivityMax: this.rangeValuesSelectivityCriteria[1],
      criteriaBlacklist: this.criteriaBlacklist,

      // Tags
      tagPenetrationMin: this.rangeValuesPenetrationTags[0],
      tagPenetrationMax: this.rangeValuesPenetrationTags[1],
      tagScorableMin: this.rangeValuesSelectivityTags[0],
      tagScorableMax: this.rangeValuesSelectivityTags[1],
      tagBlacklist: this.tagBlacklist
    };

    this.filterService.baseStatFilterRequest(this.filter);
    this.closeNav();
  }

  onTagBlacklistSelect(tag: StatItem) {
    if (!this.tagBlacklist.find(t => t.id == tag.id)) {
      this.tagBlacklist.push({id: tag.id, name: tag.name});
    }
  }

  openMatPanel(panel: MatAutocompleteTrigger) {
    setTimeout(() => {
      panel.openPanel();
    }, 400);
  }

  closeMatPanel(panel: MatAutocompleteTrigger) {
    setTimeout(() => {
      panel.closePanel();
    }, 300);
  }

  statToString(stat: StatItem) {
    return stat.name;
  }

  onTagBlacklistRemove(tag: ItemBlacklist) {
    this.tagBlacklist.splice(this.tagBlacklist.indexOf(tag), 1);
  }

  onCriteriaBlacklistSelect(criteria: StatItem) {
    if (!this.criteriaBlacklist.find(c => c.id == criteria.id)) {
      this.criteriaBlacklist.push({id: criteria.id, name: criteria.name});
    } else {
      this.criteriaBlacklist = this.criteriaBlacklist.filter(c => c.id != criteria.id);
    }
  }

  onCriteriaBlacklistRemove(criteria: ItemBlacklist) {
    this.criteriaBlacklist.splice(this.criteriaBlacklist.indexOf(criteria), 1);
  }

  onMindsetCategorySelect(mindsetCategory: MindsetCategory) {
    this.selectedMindsetCategories.push(mindsetCategory);
    this.mindsetCategories = this.mindsetCategories.filter(
      category => category.id !== mindsetCategory.id
    );

    this.searchMindsetCategory = '';
    this.mindsetCategorySearchInput.nativeElement.value = '';
  }

  removeMindsetCategory(mindsetCategory: MindsetCategory): void {
    this.selectedMindsetCategories = this.selectedMindsetCategories.filter(
      category => category.id !== mindsetCategory.id
    );
    this.mindsetCategories.push(mindsetCategory);
  }

  public resetMindsetCategorySelected() {
    console.log('resetMindsetCategorySelected')
    this.selectedMindsetCategories.forEach(category => {
        this.mindsetCategories.push(category);
    });
    this.selectedMindsetCategories = [];
    this.applyPersonaFilter();
  }
  isMinGreaterThanMax: boolean = false;

  validateInputs() {
    const min = Number(this.targetSizeMin) || 0;
    const max = Number(this.targetSizeMax) || 0;

    this.isMinGreaterThanMax = min > max;
  }

  get criteriaStat(): StatItem[] { return this._criteriaStat?.filter(c => !this.criteriaBlacklist.find(cc => cc.id == c.id)); }
  get tagStat(): StatItem[] { return this._tagStat?.filter(t => !this.tagBlacklist.find(tt => tt.id == t.id)); }

  // Arrow functions do not have their own this context; they inherit this from the parent scope at the time they are defined.
  // If it were a function, it would have its own context (which would be the context of the caller, in our case the button component)
  applyFilters() {
    if (this.router.url.includes('insights') || this.router.url.includes('persona/overview')) {
      this.applyBaseStatFilter();
    } else {
      this.applyPersonaFilter();
    }
  }

  sortbyName(a: MindsetCategory, b: MindsetCategory) {
    return a.name.localeCompare(b.name);
  }
}
