<main role="main" class="container">
  <div class="mt-3" id="step1">
    <div class="sp-create-project-selection">
        <div (click)="selectTypeOfProject('facebook-interest')" class="sp-create-project-selection-facebook-instagram sp-card-project" >
          <div class="card">
            <div class="card-header text-center">
              <img alt="" class="sp-image-facebook-import" src="assets/images/backgrounds/facebook-criteria.png" />
            </div>
            <div class="card-body sp-card-freemium">
              <h5>Facebook & Instagram audiences</h5>
              <p><span class="sp-text-secondary">Create an audience based on socio-demographic, behavioral and interest criteria.</span></p>
            </div>
            <div class="card-footer ">
              <div class="d-flex justify-content-center">
                <button class="btn btn-primary sp-btn sp-btn-primary" type="button">
                  <span>Start your analysis</span>
                </button><br>
              </div>
              <div *ngIf="freemium" class="row no-gutters mt-5">
                <div class="col-4 d-flex justify-content-end pr-2">
                  <div class="sp-interrogation">?</div>
                </div>
                <div class="col-8 sp-text-secondary">
                  Need help using the dashboard ? <br>
                  <a class="sp-visit" href="https://soprism-masterclass.thinkific.com/" target="_blank"><u>Visit the guide</u></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div (click)="selectTypeOfProject('facebook-import')" [ngClass]="{'sp-btn-project-disable' : freemium === true}" class="sp-create-project-selection-first-party sp-card-project">
          <div class="card">
            <div class="card-header text-center">
              <img alt="" class="sp-image-facebook-import" src="assets/images/backgrounds/facebook-import.svg" />
            </div>
            <div class="card-body">
              <h5>First party data audiences</h5>
              <p><span class="sp-text-secondary">Import (lookalike) custom audiences from your Facebook ads manager.</span></p>
            </div>
            <div *ngIf="!freemium" class="card-footer d-flex justify-content-center">
              <button class="btn btn-primary sp-btn sp-btn-primary" type="button">
                <span>Start your analysis</span>
              </button>
            </div>
          </div>
        </div>
        <div (click)="selectTypeOfProject('facebook-page')" [ngClass]="{'sp-btn-project-disable' : freemium === true}" class="sp-create-project-selection-fan-page sp-card-project">
          <div class="card">
            <div class="card-header text-center">
              <img alt="" class="sp-image-facebook-import" src="assets/images/backgrounds/facebook-page.svg" />
            </div>
            <div class="card-body">
             <h5>Facebook fan Page</h5>
              <p><span class="sp-text-secondary">Select the fanpage for whom you have the « advertiser » or « admin role »</span></p>
            </div>
            <div *ngIf="!freemium" class="card-footer d-flex justify-content-center">
              <button class="btn btn-primary sp-btn sp-btn-primary" type="button">
                <span>Start your analysis</span>
              </button>
            </div>
          </div>
        </div>
      <div *ngIf="freemium" class="d-flex justify-content-around align-content-center sp-freemium-notice">
        <div class="mt-3">
          <img alt="" src="assets/images/freemium/CTA@2x.png" width="100px" />
        </div>
        <div class="ml-2 mr-2 mt-2 sp-text-secondary sp-text-freemium">
          You currently have access to the free version of our solution. The use of some features is limited to the paid version. Please contact us if you wish to use this type of analysis.        </div>
        <div>
          <a href="mailto:contact@soprism.com">
            <button class="btn btn-primary sp-btn sp-btn-primary mt-4" routerLinkActive="active" style=" white-space: nowrap;text-align: center;" type="button">
              Contact us
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</main>

