import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../services/sp-authentication/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppState} from '@audienseco/auth-guardian';
import {RoleUtils} from '../../../utils/role/role-utils';
import {firstValueFrom, Subscription} from 'rxjs';
import {ErrorCode} from '../../../utils/error/error-code';
import {SocketService} from '../../../services/sp-ws/socket.service';
import {LoggerService} from '../../../services/sp-logger/logger.service';

@Component({
  selector: 'app-sp-oauth-callback',
  template: '',
  standalone: true
})
export class SpOauthCallbackComponent implements OnInit, OnDestroy {
  private ssoRedirectState?: AppState;
  private authSub: Subscription;

  constructor(
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: LoggerService,
    private socket: SocketService) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe(async params => {
      const state = params.get('state');
      const code = params.get('code');

      if (state && code) {
        this.ssoRedirectState = await this.auth.ssoHandleRedirect();
        await this.auth.ssoRequestToken();
      }
    });

    this.authSub = this.auth.sessionListener.subscribe(async authInfo => {
      if (authInfo) {
        if (!authInfo.failed) {
          if (authInfo.user.first_connection && authInfo.user.role.level >= RoleUtils.trialLevel) {
            this.router.navigate(['account/edit']).then();
          }
          else {
            if (this.ssoRedirectState?.appState.fromLogin) {
              await this.router.navigateByUrl('projects/list')
            } else {
              await this.router.navigateByUrl(this.ssoRedirectState?.appState.fromUrl ?? 'projects/list')
            }
          }

          if (!this.socket.connected) {
            firstValueFrom(this.socket.onConnect()).then(() => {
              this.socket.sendMessageType('user-login', {
                role: this.auth.session.user.role.name
              });
            });
          }
        }
        else {
          if (authInfo.errorCode === ErrorCode.ERROR_CODES.ERR_NOT_FOUND) {
            await this.router.navigate(['page-unknown-user']);
          }
          else {
            await this.router.navigate(['page-something-wrong']);
          }
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }
}
