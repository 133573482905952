import {Component, OnDestroy, OnInit} from '@angular/core';
import {SpThemingService} from '../../../../services/sp-theming/sp-theming.service';
import introJs from 'intro.js';
import {IntroService} from '../../../../services/sp-intro/intro.service';
import {SpIntroductionComponent} from '../../sp-project-list/sp-introduction/sp-introduction.component';
import {MatDialog} from '@angular/material/dialog';
import {AuthenticationService} from '../../../../services/sp-authentication/authentication.service';
import {RoleUtils} from "../../../../utils/role/role-utils";
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-sp-project-create-type-select',
  templateUrl: './sp-project-create-type-select.component.html',
  styleUrls: ['./sp-project-create-type-select.component.scss']
})
export class SpProjectCreateTypeSelectComponent implements OnInit {
  // Constructor.

  freemium = false;
  folderId: string | null = null;

  constructor(private _spTheming: SpThemingService,
              private auth: AuthenticationService,
              private route: ActivatedRoute,
              private router: Router) {
    // Set the theme to use.
    this._spTheming.setSpThemeName('dashboard-desk');
  }

  // On init.
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.folderId = params['folderId'] || null;
    });
    if (this.auth.session.authenticated) {
      this.freemium = this.auth.session.user.role.level <= RoleUtils.freemiumLevel;
    }
  }

  selectTypeOfProject(type: string) {
    if(this.folderId) {
      this.router.navigate(['/projects/create/' + type], {queryParams: {folderId: this.folderId}});
    } else {
      this.router.navigate(['/projects/create/' + type]);
    }

  }
}
