import {Injectable} from '@angular/core';
import {Project} from '../../models/project';
import {Folder} from '../../models/folder';
import {AuthenticationService} from '../sp-authentication/authentication.service';
import {ApiUserService} from '../sp-api/sp-api-user/api-user.service';
import {CrawlTrackerService} from '../sp-crawl-tracker/crawl-tracker.service';
import {Subject} from 'rxjs';
import {ApiProjectService} from '../sp-api/sp-api-project/api-project.service';
import {DataType, StatHandler} from '../../classes/data/stat-handler';
import {ApiDataService} from '../sp-api/sp-api-data/api-data.service';
import {Audience} from '../../models/audience';
import {Targeting} from '../../classes/targeting/targeting';
import {Crawl} from '../../models/crawl';
import {CrawlStatus} from '../../models/crawl-status';
import {Universe} from '../../models/universe';
import {LoggerService} from '../sp-logger/logger.service';
import {ApiAudienceService} from '../sp-api/sp-api-audience/api-audience.service';
import {Geolocation} from '../../types/facebook-types';
import {TreeNode} from '../../classes/data/tree-node';
import {PathEntry} from '../../classes/data/path-entry';
import {SocketService} from "../sp-ws/socket.service";
import {ApiFolderService} from "../sp-api/sp-api-folder/api-folder.service";

export enum ProjectSortType {
  ALPHABETIC = 'a-z',
  CREATION_DATE = 'create-desc'
}

@Injectable({
  providedIn: 'root'
})
export class ProjectManagerService {
  private _projects: Project[] = [];

  private _openedFolder: Folder;
  private _folders: Folder[] = [];
  private _customFolders: Folder[] = [];

  private _projectCount: number = 0;
  private _currProjectPage: number = 0;

  private _loaded: boolean = true;
  private _chunkLoading: boolean = false;
  private projectData: Map<number, StatHandler> = new Map();
  private personaData: Map<number, StatHandler> = new Map();
  private projectSunburstData: Map<number, TreeNode<PathEntry>[]> = new Map();

  projectAndFolderTypeFiltering: string[] = ['project', 'project_shared', 'folder'];

  projectDataLoadedListener: Subject<StatHandler> = new Subject<StatHandler>();
  projectChunkListener: Subject<Project[]> = new Subject<Project[]>();
  newFolderListener: Subject<Folder> = new Subject<Folder>();
  projectListSearchListener: Subject<string> = new Subject<string>();
  projectTypeFilterListener: Subject<string[]> = new Subject<string[]>();
  projectTypeFilterLoading: Subject<boolean> = new Subject<boolean>();
  sortType: ProjectSortType = ProjectSortType.CREATION_DATE;
  projectCreatedCount: number = 0;

  private _selectedProject: Project;
  private _selectedProjectShortcut: Project; // For project list switching, which use another simplified model

  public static readonly PROJECT_CHUNK_LIMIT: number = 15;
  private static readonly MAX_DATA_RETENTION: number = 2;
  public static readonly SEED_KEY: string = 'normalization_seed';

  // Temp stuff
  // Seed param for the normalization route of Stat API
  private _seed?: number;

  constructor(
    private auth: AuthenticationService,
    private apiUser: ApiUserService,
    private apiProject: ApiProjectService,
    private apiAudience: ApiAudienceService,
    private apiData: ApiDataService,
    private crawlTracker: CrawlTrackerService,
    private socket: SocketService,
    private logger: LoggerService)
  {
    auth.sessionListener.subscribe(session => {
      if (session) {
        if (this._projects.length > 0) {
          this.crawlTracker.clearAll();
          this.clearProjects();
          this.clearProjectsData();
        }
      } else {
        this.crawlTracker.clearAll();
        this.clearProjects();
        this.clearProjectsData();
      }
    });
    crawlTracker.crawlCriteriaDoneNotifier.subscribe(p => {
      const project = this.projects.find(proj => proj.id === p.id);
      if (!project) return;

      this.clearProjectData(project);
      this.reloadProjectInfo(project).then();
    });
    crawlTracker.crawlDoneNotifier.subscribe(p => {
      const project = this.projects.find(proj => proj.id === p.id);
      if (!project) return;

      this.clearProjectData(project);
      this.reloadProjectInfo(project).then();
    });
    crawlTracker.personaCrawlDoneNotifier.subscribe(([project, persona]: [Project, Audience]) => {
      for (const project of this.projects) {
        const foundAudience = project.audience_target.audiences_attached.find(audience => audience.id == persona.id);
        if (foundAudience) {
          this.clearProjectData(project);
          this.reloadAudienceCrawlInfo(foundAudience);
          break;
        }
      }
    });
    const storedSeed = localStorage.getItem(ProjectManagerService.SEED_KEY);
    this.seed = storedSeed ? parseInt(storedSeed) : undefined;
  }

  static getDefaultSegmentUniverse(universes: Universe[], customUniverseId?: number): Universe {
    const customSegment = universes.find(u => u.segment && u.id === customUniverseId);
    return customSegment ?? universes.find(u => u.segment_default);
  }

  addProject(project: Project) {
    if (!this._projects.find(p => p.id == project.id)) {
      this._projects.push(project);
    }
  }

  // TODO: Transform these functions as static
  public isProjectCompletelyReady(project: Project) {
    return this.isAudienceCompletelyReady(project.audience_target) &&
      this.isAudienceCompletelyReady(project.audience_bench);
  }

  public isProjectBaseReady(project: Project) {
    return this.isAudienceBaseReady(project.audience_target) &&
        this.isAudienceBaseReady(project.audience_bench);
  }

  public isAudienceBaseReady(audience: Audience) {
    const status = audience.status;
    const statusCheck = status && (status.actualStep > CrawlTrackerService.STEP_CRITERIA_AVAILABLE || status.state == 'done');

    return audience.latest_crawl?.criteria_ready || statusCheck;
  }

  public isAudienceCompletelyReady(audience: Audience) {
    const status = audience.status;
    const statusCheck = status && status.state == 'done';

    return (audience.latest_crawl?.criteria_ready && audience.latest_crawl?.segment_ready) || statusCheck;
  }

  async loadFolders(flagged: boolean = false) {
    const folders = await this.apiUser.getFolders(this.auth.session.user, flagged);
    if (!flagged) {
      this._folders = folders;
    } else {
      this._customFolders = folders;
    }

    return folders;
  }


  async loadProjectChunk(sort?: ProjectSortType, filter?: string, typeFilter?: string[]) {
    if (typeFilter && !typeFilter.includes('project') && !typeFilter.includes('project_shared')) return;

    this._chunkLoading = true;

    const loadedPage = await this.apiUser.getProjects(this.auth.session.user,
      ProjectManagerService.PROJECT_CHUNK_LIMIT,
      this._currProjectPage + 1,
      sort,
      filter,
      typeFilter,
      this._openedFolder?.id
    );
    if (this._projectCount !== loadedPage.meta.total) this._projectCount = loadedPage.meta.total;
    this._currProjectPage = loadedPage.meta.current_page;
    const projectSource = loadedPage.data;

    projectSource.forEach(p => {
      p._owner = p.user.id == this.auth.session.user.id;
    });

    const projectDuplicates =  this._projects.filter(p => projectSource.find(p2 => p2.id === p.id));

    projectDuplicates.forEach(project => {
      const index = projectSource.findIndex(p => p.id === project.id);
      projectSource[index] = project;
    })

    this._projects = this._projects
      .filter(p => !projectDuplicates.find(p2 => p2.id === p.id))
      .concat(projectSource);

    await this.trackCrawlInfo(projectSource);
    this.projectChunkListener.next(projectSource);

    this._chunkLoading  = false;
  }
  unloadProjectChunks(forceLoading: boolean = false) {
    this._chunkLoading = forceLoading || false;
    this._currProjectPage = 0;
    this._projects = [];
    this.crawlTracker.clearAll();
  }

  unloadFolders() {
    this._folders = []
    this._customFolders = [];
  }

  reloadFolders(typeFilter?: string[]) {
    this.unloadFolders();
    if (typeFilter && !typeFilter.includes('folder') && !typeFilter.includes('folder_custom')) {
      return;
    } else {
      if(typeFilter.includes('folder')) {
        this.loadFolders().then();
      }
      if(typeFilter.includes('folder_custom')) {
        this.loadFolders(true).then();
      }
    }

  }

  reloadProjectChunks(filter?: string, typeFilter?: string[]) {
    this.unloadProjectChunks();
    this.loadProjectChunk(this.sortType, filter, typeFilter).then();
  }
  async trackCrawlInfo(projects: Project[]) {
    // Crawl tracking
    for (const project of projects) {
      if (!this.isProjectCompletelyReady(project)) {
        await this.crawlTracker.addProject(project);
      }
      if (project.audience_target.audiences_attached.length > 0) {
        project.audience_target.audiences_attached.forEach(persona => {
          if (!this.isAudienceCompletelyReady(persona)) {
            this.crawlTracker.addPersona(persona,10000,project);
          }
        });
      }
    }
  }

  selectFolder(folder: Folder) {
    this._openedFolder = folder;
    this.unloadProjectChunks();
  }

  /**
   * Get project data from cache or api is not loaded yet
   * Stat handler keys: "criterion" & "tags"
   * @param project
   * @param normalized
   * @param refresh
   */
  getProjectData(project: Project, normalized: boolean = true, refresh: boolean = false): Promise<StatHandler> {
    return new Promise<StatHandler>((resolve, reject) => {
      if (this.isProjectBaseReady(project)) {
        let loadData = true;
        if (this.projectData.has(project.id) && !refresh) {
          const existingStatHandler = this.projectData.get(project.id);
          const requiredCriteriaTypes = ['tags', 'personae', 'criterion'];
          const missingCriteriaTypes = requiredCriteriaTypes.filter(x => !existingStatHandler.has(x));
          if (missingCriteriaTypes.length === 0) {
            loadData = false;
            resolve(existingStatHandler);
          }
          else {
            loadData = true;
          }
        }

        if (loadData) {
          this.apiData.getCrawledCriterion(project.audience_target, project.audience_bench, normalized, this._seed)
            .then(statHandler => {
              this.socket.sendMessageType('user-project-load', {projectName: project.name});
              this.projectData.set(project.id, statHandler);
              if (this.projectData.size > ProjectManagerService.MAX_DATA_RETENTION) {
                this.projectData.delete(this.projectData.keys().next().value);
              }
              this.projectDataLoadedListener.next(statHandler);
              resolve(statHandler)
            })
            .catch(error => {
              if (!error.status || error.status !== 401) {
                this.logger.logError("Load project data error : " + error.message, 3, error, project);
              }
              reject(error);
            });
        }
      } else {
        reject({"message": "The selected project is not ready yet"});
      }
    });
  }

  getProjectDataByType(targetAudience: Audience,
                       criteriaTypes: string[],
                       params: { normalized: boolean, pathsFilter?: string[], namesFilter?: string[], limit?: number, sortBy?: string, forceRefresh?: boolean  }) {//normalized: boolean = true, forceRefresh: boolean = false, pathsFilter: string[] = [], namesFilter: string[] = []): Promise<StatHandler> {
    return new Promise<StatHandler>((resolve, reject) => {
      if (!this.isAudienceBaseReady(targetAudience) || !this.isAudienceBaseReady(targetAudience.benchmark)) {
        reject({"message": "The selected audience is not available yet"});
      }
      const statHandler = this.getOrBuildStatHandler(targetAudience);
      const missingCriteriaTypes = params.forceRefresh ? criteriaTypes : criteriaTypes.filter(x => !statHandler.has(x));

      if (missingCriteriaTypes.length === 0) {
        resolve(statHandler);
      }
      else {
        this.apiData.getCrawledCriterionByType(targetAudience.id, targetAudience.benchmark.id, criteriaTypes, params)
          .then(criterionData => {
            missingCriteriaTypes.forEach(type => {
              statHandler.add(type, criterionData.filter(c => c.type === type));
            })

            this.projectData.set(targetAudience.id, statHandler);
            if (this.projectData.size > ProjectManagerService.MAX_DATA_RETENTION) {
              this.projectData.delete(this.projectData.keys().next().value);
            }
            this.projectDataLoadedListener.next(statHandler);

            resolve(statHandler)
          })
          .catch(error => {
            if (!error.status || error.status !== 401) {
              this.logger.logError("Load project data error : " + error.message, 3, error);
            }
            reject(error);
          });
      }
    });
  }

  private getOrBuildStatHandler(targetAudience: Audience) {
    if (this.projectData.has(targetAudience.id)) return this.projectData.get(targetAudience.id);

    const statHandler = new StatHandler();
    this.projectData.set(targetAudience.id, statHandler);
    return statHandler;
  }

  /*
      Filling the custom type through the project manager for now
      Need to do it like this because we have to notify the listeners with the updated stat handler
      and the listener is defined in the project manager.
      Ideally, anyone depending on a stat handler should somehow observe that stat handler itself
     */
  fillCustomType(statHandler: StatHandler, type: string, typesToFill: string[]){
    statHandler.fillCustomType(type, typesToFill);

    this.projectDataLoadedListener.next(statHandler);
  }

  getProjectPersonaData(project: Project): Promise<StatHandler> {
    return new Promise<StatHandler>((resolve, reject) => {
      if (this.isProjectCompletelyReady(project)) {
        const pData = this.projectData.get(project.id);
        if (pData && pData.has('personae')) {
          resolve(pData);
        } else {
          this.getProjectData(project).then(data => {
            resolve(data);
          }).catch(err => {
            if (!err.status || err.status !== 401) {
              this.logger.logError('Load project persona data error : ' + err.message, 3, err, project);
            }
            reject(err)
          });
        }
      } else {
        reject({"message": "The selected project is not ready yet"});
      }
    });
  }
  getAudiencePersonaData(persona: Audience): Promise<StatHandler> {
    return new Promise<StatHandler>((resolve, reject) => {
      //const personaStatusCheck = persona.status && (persona.status.actualStep > CrawlTrackerService.STEP_CRITERIA_AVAILABLE || persona.status.state == "done");
      //const personaBenchmarkStatusCheck = persona.benchmark && persona.benchmark.status && (persona.benchmark.status.actualStep > CrawlTrackerService.STEP_CRITERIA_AVAILABLE || persona.benchmark.status.state == "done");
      if (this.isAudienceBaseReady(persona) && this.isAudienceBaseReady(persona.benchmark)) {
        if (this.personaData.has(persona.id)) {
          const pData = this.personaData.get(persona.id);
          this.projectDataLoadedListener.next(pData);
          resolve(pData);
        } else {
          this.apiData.getCrawledCriterion(persona, persona.benchmark)
            .then(statHandler => {
              this.personaData.set(persona.id, statHandler);
              if (this.personaData.size > ProjectManagerService.MAX_DATA_RETENTION) {
                this.personaData.delete(this.personaData.keys().next().value);
              }
              this.projectDataLoadedListener.next(statHandler);
              resolve(statHandler);
            }).catch(error => {
              if (!error.status || error.status !== 401) {
                this.logger.logError("Audience persona data load error : " + error.message, 3, error);
              }
              reject(error)
          });
        }
      } else {
        reject({"message": "Your project is not available yet"});
      }
    })
  }
  getProjectSunburstData(project: Project, refresh: boolean = false, categoryFilter?: string): Promise<TreeNode<PathEntry>[]> {
    return new Promise<TreeNode<PathEntry>[]>((resolve, reject) => {
      if (this.isProjectBaseReady(project)) {
        if (this.projectSunburstData.has(project.id) && !refresh) {
          resolve(this.projectSunburstData.get(project.id));
        } else {
          this.apiData.getSunburstData(project, categoryFilter).then(tree => {
            this.projectSunburstData.set(project.id, tree);
            if (this.projectSunburstData.size > ProjectManagerService.MAX_DATA_RETENTION) {
              this.projectSunburstData.delete(this.projectSunburstData.keys().next().value);
            }
            resolve(tree);
          }).catch(err => {
            if (!err.status || err.status !== 401) {
              this.logger.logError('Sunburst data load failed', 3, err, project);
            }
            reject(err);
          });
        }
      }
    });
  }
  clearProjects() {
    this._projects = [];
    this._currProjectPage = 0;
    this._selectedProject = undefined;
  }
  clearProjectsData() {
    this.projectData.clear();
    this.projectSunburstData.clear();
    this.personaData.clear();
  }

  clearProjectData(p: Project) {
    this.projectData.delete(p.id);
    this.personaData.delete(p.audience_target.id);
    p.audience_target.audiences_attached.forEach(a => {
      this.personaData.delete(a.id);
    });
    this.projectSunburstData.delete(p.id);
  }

  clearProjectSunburstData(p: Project) {
    this.projectSunburstData.delete(p.id);
  }
  async addProjectAtFirstPosition(project: Project) {
    project._owner = this.auth.session.user.id === project.user.id;

    // Removing existing project with same id
    if (this._projects.find(p => p.id === project.id)) {
      this.removeLocalProject(project);
    }

    await this.crawlTracker.addProject(project);
    this._projects.unshift(project);
  }
  addFolder(folder: Folder, custom: boolean) {
    if (custom) this._customFolders.push(folder);
    else this._folders.push(folder);

    this.newFolderListener.next(folder);
  }
  trackPersona(persona: Audience) {
    if (!this.crawlTracker.inPersonaeWaitingRoom(persona)) {
      this.crawlTracker.addPersona(persona,10000, this.selectedProject);
    }
  }
  removeLocalProject(project: Project) {
    this._projects = this._projects.filter(p => p.id !== project.id);
  }
  /**
   * Pseudo-caching project
   * @param id
   */
  async getProject(id: number): Promise<Project> {
    let project = this.projects.find(p => p.id === id);
    if (project) {
      //project._owner = project.user.id == this.auth.session.user.id;
      if (!project._ready) this.addProject(project);
      return project;
    }
    else {
      project = await this.apiProject.getOne(id);
      project._owner = project.user.id == this.auth.session.user.id;
      project.audience_target.audiences_attached.forEach(persona => {
        this.crawlTracker.addPersona(persona,10000, this.selectedProject);
      });

      this.addProject(project);

      return project;
    }
  }
  async reloadProjectInfo(project: Project) {
    const p = await this.apiProject.getOne(project.id);
    project.audience_target.latest_crawl = p.audience_target.latest_crawl;
    project.audience_bench.latest_crawl = p.audience_bench.latest_crawl;
    project.audience_target.universes = p.audience_target.universes;
    project.audience_bench.universes = p.audience_bench.universes;
  }
  reloadAudienceCrawlInfo(audience: Audience) {
    this.apiAudience.getOne(audience.id).then(a => {
      audience.latest_crawl = a.latest_crawl;
    });
  }
  removeFolder(folder: Folder) {
    if (!folder.flag) this._folders = this._folders.filter(f => f.id !== folder.id);
    else this._customFolders = this._customFolders.filter(f => f.id !== folder.id);
  }

  get openedFolder(): Folder { return this._openedFolder; }
  get folders(): Folder[] { return this._customFolders.concat(this._folders); }
  get projects(): Project[] { return this._projects ;}
  get allProjects(): Project[] { return this.auth.session.authenticated ? this.auth.session.user.projects : []; }
  get loaded(): boolean { return this._loaded; }
  get chunkLoading(): boolean { return this._chunkLoading; }
  get projectCount(): number { return this._projectCount; }

  get selectedProject(): Project {
    return this._selectedProject;
  }
  set selectedProject(project: Project) {
    this._selectedProject = project;
    /*if (this.projects.length == 0) this._selectedProject = project;
    else this._selectedProject = this.projects.find(p => p.id == project.id);*/

    // Setting shortcut value based on ID
    this._selectedProjectShortcut = this.allProjects.find(p => p.id == project.id);
  }
  get selectedProjectShortcut(): Project {
    return this._selectedProjectShortcut;
  }
  set selectedProjectShortcut(project: Project) {
    this._selectedProjectShortcut = project;
  }
  set seed(seed: number|undefined) {
    this._seed = seed;
    if (seed) localStorage.setItem(ProjectManagerService.SEED_KEY, seed.toString(10));
    else localStorage.removeItem(ProjectManagerService.SEED_KEY);
  }

  get seed() {
    return this._seed;
  }
}
