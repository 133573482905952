<div class="mt-5 sp-table-div" id="step4">
  <div class="row d-flex no-gutters sp-table-header">
    <div class="col-5 align-self-center">
      <mat-form-field appearance="outline" class="w-100 ml-3 mt-3 mb-1 sp-form-field">
        <mat-label class="sp-input-label">Search</mat-label>
        <mat-icon class="sp-input-search-icon"  matPrefix svgIcon="icon_sp_search"></mat-icon>
        <input #searchData (keyup)="applyFilter($event)" [ngModel]="searchText" class=" sp-input-search-content " matInput placeholder="Search a company members...">
        <button (click)="dataSource.filter=''; searchData.value=''" *ngIf="dataSource.filter" aria-label="Clear" mat-icon-button matSuffix>
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="col-2 ml-3 mt-4 mb-1 align-self-center">
      <p class="pl-3 sp-table-number">
        {{ auth.session.user.company.users.length}} company members
      </p>
    </div>

    <div *ngIf="canManageUsers" class="col-5 text-right">
      <button (click)="createNewCompanyUser()" class="btn btn-primary rounded-circle sp-btn sp-btn-xl sp-btn-primary sp-btn-circle sp-out" matTooltip="Add a new user" matTooltipHideDelay="10" type="button">
        <span class="sp-icon">
          <i class="icon-sp-users-add-button"> </i>
        </span>
      </button>
    </div>
  </div>

  <table [dataSource]="dataSource" class="mat-elevation-z8 sp-table" mat-table matSort>
    <ng-container matColumnDef="image">
      <th *matHeaderCellDef mat-header-cell ></th>
      <td (click)="goToUserUpdate(element)" *matCellDef="let element" mat-cell>
        <img *ngIf="element.image !== null" [src]="element.image !== null ? environment.config.api.storageUrl + element.image : environment.config.noPpImage" alt="Profile picture" class="sp-avatar sp-avatar-md" style=" margin-top: 0.6rem;margin-bottom: 0.6rem;">
        <span *ngIf="element.image == null" class="avatar-rounded " style=" margin-top: 0.6rem;margin-bottom: 0.6rem;">
            <span class="avatar-user-name text-uppercase">{{element.first_name.substr(0,1)}}{{element.last_name.substr(0,1)}}</span>
          </span> </td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th *matHeaderCellDef mat-header-cell mat-sort-header> Name </th>
      <td (click)="goToUserUpdate(element)" *matCellDef="let element" mat-cell style="text-transform: capitalize"> {{ element.first_name + " " + element.last_name }} </td>
    </ng-container>


    <ng-container matColumnDef="email">
      <th *matHeaderCellDef mat-header-cell mat-sort-header> Email </th>
      <td (click)="goToUserUpdate(element)" *matCellDef="let element" mat-cell> {{ element.email }} </td>
    </ng-container>

    <ng-container *ngIf="canManageUsers" matColumnDef="type">
      <th *matHeaderCellDef mat-header-cell> User Type </th>
      <td *matCellDef="let element" mat-cell>
        <mat-form-field class="w-100 sp-form-field sp-select-user-type" matTooltip="{{(!canManageUsers || element.id == auth.session.user.id) ? 'This information cannot be modified' : ''}} " matTooltipHideDelay="10" style="height: 40px">
          <mat-select (selectionChange)="onRoleSelectionChange(element, $event.value)" [disabled]="!canManageUsers || element.id == auth.session.user.id" [value]="element.role.id">
            <mat-option *ngFor="let role of roles" [disabled]="isRoleDisabled(element, role)" [value]="role.id" > {{ role.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container *ngIf="!canManageUsers" matColumnDef="type-user">
      <th *matHeaderCellDef mat-header-cell> User Type </th>
      <td *matCellDef="let element" mat-cell> {{ element.role.name }} </td>
    </ng-container>

    <ng-container *ngIf="canManageUsers" matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell> Actions </th>
      <td *matCellDef="let element" mat-cell>
        <i (click)="goToUserUpdate(element)" class="icon-sp-edit-white"> </i>
        <i (click)="openDeleteDialog(element)" class="icon-sp-delete"> </i>
        <i *ngIf="canSendInvitationMap[element.id] | async" class="icon-sp-email" (click)="sendInvitation(element)"> </i>
        <i *ngIf="(invitationPending | async).includes(element.id)" class="icon-sp-update"></i>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
  </table>
  <div class="mb-3 sp-table-footer" style="min-height: 5vh">
    <button (click)="openUserAddDialog()" *ngIf="isCompanySuperAdmin" class="btn btn-primary m-3 sp-btn sp-btn-sm sp-btn-primary" matTooltip="Add an existing user" matTooltipHideDelay="10" type="button">
      <span>Add an existing user</span>
    </button>
  </div>
</div>
<mat-paginator [ngStyle]="{'display': dataSource.data.length > 10  ? 'block' :  'none'}" [pageSize]="10" class="sp-paginator" hidePageSize showFirstLastButtons></mat-paginator>
