import {Pipe, PipeTransform} from '@angular/core';
import {Logger} from '../classes/util/logger';

@Pipe({
  standalone: true,
  name: 'FilterPipe'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter( it => {
      if (typeof it === 'string') {
        return it.toLowerCase().includes(searchText);
      } else if (it.hasOwnProperty('name')) {
        return it.name.toLowerCase().includes(searchText);
      } else {
        Logger.logWarning("Filter pipe: item or item name property is undefined");
        return true;
      }
    });
  }
}
