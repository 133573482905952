import {Component, OnDestroy, OnInit} from '@angular/core';
import { SpThemingService } from '../../../services/sp-theming/sp-theming.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormControl, Validators} from '@angular/forms';
import {ErrorCode} from '../../../utils/error/error-code';
import {AuthenticationService} from '../../../services/sp-authentication/authentication.service';
import {Message} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {firstValueFrom, Observable, Subscription} from 'rxjs';
import {RoleUtils} from '../../../utils/role/role-utils';
import {SocketService} from '../../../services/sp-ws/socket.service';


@Component({
  selector: 'app-sp-user-login',
  templateUrl: './sp-user-login.component.html',
  styleUrls: ['./sp-user-login.component.scss'],
  providers: [MessageService]
})
export class SpUserLoginComponent implements OnInit, OnDestroy {
  hide = true;
  protected auth0Enabled: Observable<boolean> = null;

  protected emailControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required
  ]);

  protected passwordControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required
  ]);

  protected waiting: boolean = false;
  protected authError: string = null;
  private oneTimeAuth: boolean = false;

  msgs: Message[] = [];

  // Subscriptions
  private authSub: Subscription;

  // Constructor.
  constructor(private _spTheming: SpThemingService,
              private auth: AuthenticationService,
              public router: Router,
              private route: ActivatedRoute,
              private socket: SocketService
              ) {
    // Set the theme to use.
    this._spTheming.setSpThemeName('dashboard-welcome');
  }

  // On init.
  ngOnInit() {
    if (this.auth.session.initialized && this.auth.session.authenticated) { this.router.navigateByUrl('/').then(); }

    this.route.queryParamMap.subscribe(async params => {
      const secret = params.get('s');
      const from = params.get('from');
      const state = params.get('state');

      if (from === 'invite' || state === 'confirmed') {
        this.showNotification('success', 'Registration successful', 'You can now login with your credentials.');
      }
      if (secret !== null) {
        this.waiting = true;

        this.oneTimeAuth = true;
        this.auth.loginOtp(secret).then(() => {
          const tmpSub = this.auth.sessionListener.subscribe(session => {
            if (session) {
              this.router.navigateByUrl('account/edit').then();
            } else {
              this.waiting = false;
            }

            tmpSub.unsubscribe();
          });
        }).catch(err => {
          if (err.status && err.status === 401) {
            this.showNotification('error', 'Error Message', 'Your login link has expired.');
          }
        });
      }
    });

    // Listener for session authentication
    this.authSub = this.auth.sessionListener.subscribe(async authInfo => {
      if (authInfo && !this.oneTimeAuth) {
        if (!authInfo.failed) {
          if (authInfo.user.first_connection && authInfo.user.role.key !== RoleUtils.trialKey
            && authInfo.user.role.level > RoleUtils.freemiumLevel) {
            this.router.navigate(['account/edit']).then();
          }
          else {
            await this.router.navigateByUrl('projects/list');
          }

          if (!this.socket.connected) {
            firstValueFrom(this.socket.onConnect()).then(() => {
              this.socket.sendMessageType('user-login', {
                role: this.auth.session.user.role.name
              });
            });
          }
        }
        else {
          if (authInfo.errorCode === ErrorCode.ERROR_CODES.ERR_AUTH_FAILURE || authInfo.errorCode === ErrorCode.ERROR_CODES.ERR_FORM) {
            this.authError = authInfo.errorMessage ?? 'Invalid credentials, please try again.';
          } else if (authInfo.errorCode === ErrorCode.ERROR_CODES.ERR_FORBIDDEN) {
            this.authError = authInfo.errorMessage ?? 'Could not login: forbidden';
          } else {
            this.authError = 'An unexpected error has occurred. If this persists, contact an administrator (code ' + authInfo.errorCode + ')';
          }

          this.showNotification('error', 'Error Message', this.authError);
          this.waiting = false;
        }
      }
    });

    this.auth0Enabled = this.auth.auth0EnabledObservable();
  }

  ngOnDestroy() {
    if (this.authSub) { this.authSub.unsubscribe(); }
  }

  isSubmitEnabled(){
    return !this.waiting && this.emailControl.valid && this.passwordControl.valid;
  }

  private showNotification(type: 'error'|'success'|'info', title: string, message: string) {
    this.msgs = [];
    this.msgs.push({severity: type, summary: title, detail: message});
  }

  async keyDownFunction(event: KeyboardEvent) {
    if (event.code === "Enter" && this.isSubmitEnabled()) {
      await this.onClassicLogin();
    }
  }

  /**
   * Sign in button events
   */
  async onClassicLogin() {
    this.waiting = true;
    this.emailControl.markAsTouched();
    this.passwordControl.markAsTouched();

    await this.auth.classicLogin(this.emailControl.value, this.passwordControl.value);
    this.waiting = false;
  }

  async onSsoLogin() {
    await this.auth.ssoLogin({fromLogin: true});
  }
}

