<div class="w-100 container">
  <div *ngIf="env.config.enableDebugFeatures" class="row d-flex no-gutters" style="margin-bottom: 1rem">
    <h4 class="mr-1">Debug options</h4>
    <button (click)="facebookBusinessLogin()" class="btn btn-primary sp-btn-primary mr-1">Business scope Login</button>
    <button (click)="facebookGetStatus()" class="btn btn-primary sp-btn-primary mr-1">Dump Facebook Info</button>
    <button (click)="facebookLogout()" class="btn btn-primary sp-btn-primary mr-1">Facebook Logout</button>
    <button (click)="facebookUnlink()" class="btn btn-primary sp-btn-primary mr-1">Facebook Unlink App</button>
    <button (click)="facebookApiTestCall()" class="btn btn-primary sp-btn-primary mr-1">Facebook API Test</button>
  </div>
  <div class="row d-flex no-gutters">
    <div class="col-10 d-flex">
      <!-- (change)="onImageChange(imagePicker)" -->
      <input #imagePicker  (change)="onImageChange(imagePicker)" [hidden]="true" accept="image/jpeg,image/gif,image/png" type="file"/>
      <span *ngIf="selectedImage != null ">
        <img (click)="openUserImageDialog()" [src]="selectedImage" alt="Profile picture" class="sp-avatar sp-avatar-xl">
      </span>
      <span (click)="openUserImageDialog()" *ngIf="selectedImage == null " class="avatar-user d-flex align-items-center justify-content-center">
        <span class="avatar-user-name"> {{this.auth.session.user?.first_name.substr(0,1)}}{{this.auth.session.user?.last_name.substr(0,1)}} </span>
      </span>
      <h3 style="transform: translateY(13px)">
        <span class="pl-3 pr-3" matTooltip="{{this.auth.session.user?.first_name}} {{this.auth.session.user?.last_name}}" matTooltipHideDelay="10">
          {{this.auth.session.user?.first_name}}
          <span *ngIf="this.auth.session.user?.last_name.length > 10">
            {{this.auth.session.user?.last_name.substr(0,10)}}...
          </span>
           <span *ngIf="this.auth.session.user?.last_name.length <= 10">
            {{this.auth.session.user?.last_name}}
          </span>
        </span>
        <span class="sp-text-secondary border-left pl-3">{{this.auth.session.user?.company.name}}</span>
      </h3>
    </div>
    <div class="col-2 text-right">
      <button (click)="resetData()" [hidden]="updatingInfo || !this.dataHasChanged " class="btn btn-secondary sp-btn sp-btn-secondary" matTooltip="Back to projects" matTooltipHideDelay="10"  routerLinkActive="active" type="button">
        <span>Cancel</span>
      </button>
      <button (click)="submitFormButton.click()" [disabled]="updatingInfo" [hidden]="!this.dataHasChanged " class="ml-3 btn btn-primary sp-btn sp-btn-primary" matTooltip="Update your account" matTooltipHideDelay="10" type="button">
        <span>Update profile</span>
      </button>
    </div>
  </div>
  <div *ngIf="env.config.dev || !env.online" class="sp-card pt-3">
    <div class="card">
      <div class="card-header">Ultra secret options
        <span class="sp-information-tooltip" matTooltip="This is an ultra secretely secret menu please do not tell anyone about this." matTooltipHideDelay="10">
          <span class="">i</span>
        </span>
      </div>
      <div class="card-body pl-3 pr-3">
        <mat-form-field appearance="outline" class="w-25">
          <mat-label class="sp-input-label">Normalization seed</mat-label>
          <mat-select placeholder="No seed - Full Random behavior" (selectionChange)="onSeedSelectionChange($event.value)" [value]="seedSelected">
            <mat-option [value]="undefined">No seed - Full Random behavior</mat-option>
            <mat-option *ngFor="let seed of seedSelection" [value]="seed">{{ seed }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row no-gutters pt-3">
    <div class="col-7 sp-col" id="step1">
      <div class="sp-card ">
        <form (ngSubmit)="updateUserInfo()" [formGroup]="userForm" class="card sp-card-data">
          <div class="card-header">My details

            <span class="sp-information-tooltip" matTooltip="This section allows you to update your personal information and your password. To update your company information, contact our support team." matTooltipHideDelay="10">
              <span class="">i</span>
            </span>
          </div>
          <button hidden class="" type="submit" #submitFormButton></button>
          <div class="card-body">
            <div class="row pt-3 pl-4 pr-4 pb-3">
              <div class="col-6">
                <mat-form-field class="w-100 sp-form-field" appearance="outline">
                  <mat-label class="sp-input-label">First Name</mat-label>
                  <input #first_name (input)="onFirstNameChange(first_name.value)" [value]="userForm.get('first_name').value" class="sp-input-content"  formControlName="first_name" matInput placeholder="Fill your first name"/>
                  <mat-error *ngIf="userForm.get('first_name').hasError('required')">
                    Please enter your <strong>first name</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field class="w-100 sp-form-field" appearance="outline">
                  <mat-label class="sp-input-label">Last Name</mat-label>
                  <input #last_name (input)="onLastNameChange(last_name.value)" class="sp-input-content" formControlName="last_name" matInput placeholder="Fill your last name"/>
                  <mat-error *ngIf="userForm.get('last_name').hasError('required')">
                    Please enter your <strong>last name</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row pl-4 pr-4 pb-3">
              <div class="col">
                <mat-form-field class="w-100 sp-form-field" appearance="outline">
                  <mat-label class="sp-input-label">Email address</mat-label>
                  <input #email (input)="onEmailChange(email.value)" class="sp-input-content" formControlName="email" matInput placeholder="Fill your email address"/>
                  <mat-error *ngIf="userForm.get('email').hasError('required')">
                    Please enter an <strong>email </strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="!(auth0Enabled | async)" class="row pb-3 pl-4 pr-4">
              <div class="col">
                <mat-form-field class="w-100 sp-form-field" appearance="outline">
                  <mat-label class="sp-input-label">Password</mat-label>
                  <input #password (input)="onPasswordChange(password.value)" autocomplete="new-password" class="sp-input-content" formControlName="passwordChange" matInput placeholder="Fill your new password" type="password"/>
                  <mat-error *ngIf="userForm.get('passwordChange').hasError('minlength')">
                    Please enter a <strong> password </strong> with at least 10 characters
                  </mat-error>
                  <mat-error *ngIf="userForm.get('passwordChange').hasError('pattern') && !userForm.get('passwordChange').hasError('minlength')">
                    Please enter a <strong> valid password </strong> with at least <strong>1 uppercase character</strong>, <strong>1 lowercase character</strong> and <strong>1 number</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="w-100 sp-form-field" appearance="outline">
                  <mat-label class="sp-input-label">Confirm password</mat-label>
                  <input #confirm_password autocomplete="new-password" (input)="onPasswordConfirmChange(password.value,confirm_password.value)" [required]="userForm.get('passwordChange').value !== ''" class="sp-input-content" formControlName="passwordConfirm" matInput placeholder="Confirm your new password" type="password"/>
                  <mat-error *ngIf="userForm.get('passwordConfirm').hasError('passwordMatch')">
                     Passwords <strong> do not match </strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row pb-3 pl-4 pr-4">
              <div class="col">
                <mat-form-field  appearance="outline" class="w-100 sp-form-field" matTooltip="This information cannot be modified" matTooltipHideDelay="10">
                  <mat-label class="sp-input-label">User role</mat-label>
                  <input disabled [value]="loadedUser ? loadedUser.role.name : ''" class="sp-input-content" matInput/>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field  appearance="outline" class="w-100 sp-form-field" matTooltip="This information cannot be modified" matTooltipHideDelay="10">
                  <mat-label class="sp-input-label">Company</mat-label>
                  <input class="sp-input-content" formControlName="company" matInput placeholder="User's company"/>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-5 sp-col pl-3">
      <div class="row h-100">
        <div class="w-100 h-100 sp-card" id="step3">
          <div class="card sp-card-credit">
            <div class="card-header">Credits Left
              <span class="sp-information-tooltip" matTooltip=" Thanks to this section, you can track the credits left to your company." matTooltipHideDelay="10">
                <span class="">i</span>
              </span>
            </div>
            <div class="card-body d-flex justify-content-between align-items-center p-3">
                <div class="text-center" style="width: 31%;">
                  <div id="container"></div>
                </div>
                <div class="text-center" style="width: 31%;">
                  <div id="container-2"></div>
                </div>
                <div class="text-center" style="width: 31%;">
                  <div id="container-3"></div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div  class="d-inline-flex justify-content-between w-100 mt-5 sp-user-info-card" style="column-gap: 2rem">
    <div (click)="facebookBusinessLogin()" class="card" id="card-1" ngClass="{{ fbConnection === null ? '' : (fbConnection === false ? 'refusedCard' : 'completedCard')}}">
      <div class="card-header">
        <span><b>Connect your Facebook account</b></span>
      </div>
      <div class="card-body p-3">
        <span>In order for SoPRISM to analyze your audiences we need to access to your Facebook account.</span>
      </div>
      <div class="card-footer">
        <button *ngIf="fbConnection == null" class="btn btn-primary rounded-circle sp-btn sp-btn-primary sp-btn-circle" type="button">
          <span class="sp-icon-scroll">
            <mat-icon>arrow_forward</mat-icon>
          </span>
        </button>

        <div *ngIf="fbConnection == true" >
          <img src="assets/images/icons/soprism/Check.svg">
        </div>
        <div *ngIf="fbConnection == false">
          <img src="assets/images/icons/soprism/Attention.svg">
        </div>
      </div>
    </div>
    <div (click)="openAdAccountDialog()" class="card" id="card-2" ngClass="{{ !fbConnection ? 'disableCard' : validAdAccounts ?  'completedCard' : ''}}" [matTooltip]=" !fbConnection ? 'You need first to connect your Facebook account before choosing your ads account' : '' " matTooltipHideDelay="10">
      <div class="card-header">
        <span><b>Manage your ads account</b></span>
      </div>
      <div class="card-body p-3">
        <span>You need to select the ad accounts you want to use to get access to your own Facebook audiences</span>
      </div>
      <div class="card-footer">
        <button *ngIf="!fbConnection || !validAdAccounts" class="btn btn-primary rounded-circle sp-btn sp-btn-primary sp-btn-circle" type="button">
          <span class="sp-icon-scroll">
            <mat-icon>arrow_forward</mat-icon>
          </span>
        </button>
        <div *ngIf="fbConnection && validAdAccounts == true" >
          <img src="assets/images/icons/soprism/Check.svg">
        </div>
      </div>
    </div>
    <div (click)="navigateToNewProject()" [matTooltip]="(!validAdAccounts || adAccounts.length == 0) && !auth.session.user.first_project_created ? 'You need first to select your Facebook ads account before launching your first project' : '' " class="card" id="card-3" matTooltipHideDelay="10" ngClass="{{ auth.session.user.first_project_created && fbConnection && validAdAccounts && adAccounts.length > 0 ? 'completedCard' : (!validAdAccounts || !fbConnection || adAccounts.length == 0? 'disableCard' : '') }}">
        <div class="card-header">
          <span><b>{{ auth.session.user.first_project_created ? 'Launch a new project' : 'Launch your first project' }}</b></span>
        </div>
        <div *ngIf="fbConnection && validAdAccounts && adAccounts.length > 0" class="card-body p-3">
          <span *ngIf="!auth.session.user.first_project_created">
            You just finished to set-up your account. You are ready to launch your first project
          </span>
          <span *ngIf="auth.session.user.first_project_created">
            Your account is set-up and up to date. You can click here to launch a new project
          </span>
        </div>
        <div *ngIf="!fbConnection || !validAdAccounts" class="card-body p-3">
          <span *ngIf="!auth.session.user.first_project_created">
            You need to set-up your account in order to launch your first project
          </span>
          <span *ngIf="auth.session.user.first_project_created">
            You need to set-up your account in order to launch a new project
          </span>
        </div>
        <div class="card-footer">
          <button (click)="navigateToNewProject()" *ngIf="!auth.session.user.first_project_created || !fbConnection || !validAdAccounts || adAccounts.length == 0" class="btn btn-primary rounded-circle sp-btn sp-btn-primary sp-btn-circle" id="sp-btn-scroll" type="button">
            <span class="sp-icon-scroll">
              <mat-icon>arrow_forward</mat-icon>
            </span>
          </button>
          <div *ngIf="auth.session.user.first_project_created && fbConnection && validAdAccounts && adAccounts.length > 0" >
            <img src="assets/images/icons/soprism/Check.svg">
          </div>
        </div>
    </div>
  </div>
</div>
<app-sp-admin-company-users [company]="this.createdCompany"></app-sp-admin-company-users>
