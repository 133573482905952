import {Component} from '@angular/core';

@Component({
  selector: 'divider',
  standalone: true,
  template: '',
  host: {
    class: 'border-left border-bottom'
  }
})
export class TitanDivider {
}
