import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {User} from '../../../../models/user';
import {Role} from '../../../../models/role';
import {RoleUtils} from '../../../../utils/role/role-utils';
import {SpModalUserDeleteComponent} from '../../../sp-modal/sp-modal-user-delete/sp-modal-user-delete.component';
import {Company} from '../../../../models/company';
import {environment} from '../../../../../environments/environment';
import {SpModalCompanyAddUsersComponent} from '../sp-admin-company-create/sp-modal-company-add-users/sp-modal-company-add-users.component';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {Router} from '@angular/router';
import {ApiUserService} from '../../../../services/sp-api/sp-api-user/api-user.service';
import {AlertService} from '../../../../services/sp-alert/alert.service';
import {ApiCompanyService} from '../../../../services/sp-api/sp-api-company/api-company.service';
import {AuthenticationService} from '../../../../services/sp-authentication/authentication.service';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {AsyncPipe, NgForOf, NgIf, NgStyle} from '@angular/common';
import {ApiRoleService} from '../../../../services/sp-api/sp-api-role/api-role.service';
import {BehaviorSubject, Observable, combineLatest, of} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-sp-admin-company-users',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatTableModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSortModule,
    MatPaginatorModule,
    NgStyle,
    NgIf,
    NgForOf,
    AsyncPipe
  ],
  templateUrl: './sp-admin-company-users.component.html',
  styleUrls: ['./sp-admin-company-users.component.scss']
})

export class SpAdminCompanyUsersComponent implements OnInit, OnChanges {
  // User IDs of already resent invitation
  private invitationSentSubject = new BehaviorSubject<number[]>([]);
  private invitationPendingSubject = new BehaviorSubject<number[]>([]);

  invitationSent = this.invitationSentSubject.asObservable();
  invitationPending = this.invitationPendingSubject.asObservable();
  canSendInvitationMap: { [userId: number]: Observable<boolean> } = {};

  switchRolePending = false;
  dataSource = new MatTableDataSource<User>([]);
  roles: Array<Role> = [];
  canManageUsers: boolean = false;
  isCompanySuperAdmin: boolean = false;

  auth0Enabled: Observable<boolean> = null;

  displayedColumns: string[] = [
    'image',
    'last_name',
    'email'
  ];
  searchText= '';

  @Input() company: Company = null;
  authenticatedUserRoleLevel: number = null;

  constructor(private dialog: MatDialog,
              private router: Router,
              private apiUser: ApiUserService,
              private apiRole: ApiRoleService,
              private alert: AlertService,
              private apiCompany: ApiCompanyService,
              public auth: AuthenticationService) { }

  async ngOnInit() {
    this.auth0Enabled = this.auth.auth0EnabledObservable();

    const user = await this.apiUser.getOne(this.auth.session.user.id);
    this.authenticatedUserRoleLevel = user.role.level;

    this.canManageUsers = await this.auth.permissionCheck("company.self.user.manage");

    if (this.canManageUsers) this.displayedColumns.push("type", "actions");
    else this.displayedColumns.push("type-user");

    this.isCompanySuperAdmin = await this.auth.permissionCheck("company.admin");

    if (this.canManageUsers) {
      this.apiRole.getAll().then((roles: Role[]) => {
        this.roles = roles;
      });
    }

  }

  ngOnChanges() {
    if (this.company?.users) this.dataSource.data = this.company.users;

    if (this.company?.users) {
      this.company.users.forEach(user => {
        this.canSendInvitationMap[user.id] = combineLatest([
          this.auth0Enabled,
          this.invitationSent,
          this.invitationPending
        ]).pipe(
          map(([auth0Enabled, invitationSent, invitationPending]) => {
            const isUserActivated = auth0Enabled ? user.login_at : user.activated;
            return (
              !isUserActivated &&
              !invitationSent.includes(user.id) &&
              !invitationPending.includes(user.id)
            );
          })
        );
      });
    }
  }

  openUserAddDialog() {
    const dialogRef = this.dialog.open(SpModalCompanyAddUsersComponent,{
      maxHeight:'30rem',maxWidth:'40rem', minWidth:'40rem', data: {
        company: this.company
      }
    });

    dialogRef.afterClosed().subscribe(company => {
      if(company !== null && company !== undefined) {
        this.company = company;
        this.dataSource.data = this.company.users;
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToUserUpdate(user: User) {
    this.router.navigate(["/admin/users/update/" + user.id], {
      queryParams: {
        'from': 'user-account'
      }
    });
  }

  switchUserCompanyRole(user: User, company: Company, roleId: number) {
    this.switchRolePending = true;

    const role = this.roles.find(r => r.id == roleId);

    this.apiUser.switchCompanyRole(user, company, role)
      .then(() => {
        user.role = role;
        this.dataSource.data = this.company.users;
      })
      .catch(error => {
        if(environment.config.showErrorModal) this.alert.notify("Error", error.error.message, "error");
      })
      .finally(() => this.switchRolePending = false);
  }

  deleteUser(user: User) {
    this.apiCompany.deleteUser(this.company, user)
      .then(company => {
        if (company) {
          this.company = company;
          this.dataSource.data = this.company.users;
        }
      })
      .catch(error => {
        if(environment.config.showErrorModal) this.alert.notify("Error", error.error.message, "error");
      });
  }

  openDeleteDialog(user: User): void {
    const dialogRef = this.dialog.open(SpModalUserDeleteComponent,{
      maxHeight:'30rem',maxWidth:'40rem', data: {
        user,
        company: this.company
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.deleteUser(user);
      }
    });
  }

  onRoleSelectionChange(user: User, roleId: number) {
    this.switchUserCompanyRole(user, this.company, roleId);
  }

  isRoleDisabled(user: User, role: Role): boolean {
    if(this.company) {
      if(user.role.level >= RoleUtils.teamAdminLevel && this.company.users.filter(u => u.role.level >= RoleUtils.teamAdminLevel).length <= 1) {
        return role.level < RoleUtils.teamAdminLevel || role.level > this.auth.session.user.role.level;
      }
      if (this.authenticatedUserRoleLevel < RoleUtils.superAdminLevel && role.level <= RoleUtils.trialLevel) {
        return true;
      }

      return role.level > this.auth.session.user.role.level;
    }
  }

  canSendInvitation(user: User): Observable<boolean> {
    return combineLatest([this.auth0Enabled, this.invitationSent, this.invitationPending]).pipe(
      switchMap(([auth0Enabled, invitationSent, invitationPending]) => {
        const isUserActivated = auth0Enabled ? user.login_at : user.activated;
        return of(!isUserActivated && !invitationSent.includes(user.id) && !invitationPending.includes(user.id));
      })
    );
  }

  async sendInvitation(user: User) {
    const invitationPending = this.invitationPendingSubject.getValue();
    invitationPending.push(user.id);
    this.invitationPendingSubject.next([...invitationPending]);

    try {
      await this.auth.auth0Enabled() ? await this.apiUser.resendInvitationAuth0(user) : await this.apiUser.sendInvitation(user);
      this.alert.notify('OK!', 'The invitation has been sent successfully', 'success');

      const invitationSent = this.invitationSentSubject.getValue();
      invitationSent.push(user.id);
      this.invitationSentSubject.next([...invitationSent]);
    } catch (err) {
      this.alert.notify('Error', err.error.message, 'error');
    } finally {
      const updatedInvitationPending = this.invitationPendingSubject.getValue().filter(id => id !== user.id);
      this.invitationPendingSubject.next([...updatedInvitationPending]);
    }
  }

  protected readonly environment = environment;

  createNewCompanyUser() {
    this.router.navigate(['/admin/users/create'], {
      queryParams: {
        'from': 'company',
        'company': this.company.id
      }
    }).then();
  }

}
