import {User} from "./user";
import {Injectable} from '@angular/core';
import {ErrorCode} from '../utils/error/error-code';
import {Subject} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {environment as env} from '../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class Session {
  private readonly authInfoCookie: string = 'sp-creds';

  private _user: User = null;
  private _error: any = null;
  private _httpErrorCode = null;
  private _initialized: boolean = false;

  private _sessionListener: Subject<Session> = new Subject();

  constructor(private cookieService: CookieService) {
  }

  initialize() {
    this._initialized = true;
    this._sessionListener.next(this);
  }

  resetSession(){
    this._user = null;
    this._error = null;
    this._initialized = false;
  }

  newSessionWithError(error: any) {
    this.resetSession();
    this.setupError(error);
    this.initialize();
  }

  private setupError(error: any) {
    if (error instanceof HttpErrorResponse) {
      this._error = error.error;
      this._httpErrorCode = error.status;
    } else {
      this._error = error;
    }
  }

  newSessionWithUser(user: User) {
    this.resetSession();
    this._user = user;
    this.initialize();
  }

  sessionClear() {
    this.cookieService.delete(this.authInfoCookie, '/');
    this.resetSession();
    this.sessionListener.next(undefined);
  }

  storeAuthInfo(token: string, socketToken: string = undefined){
    const secure: boolean = env.online;

    const data = { token, socketToken };

    this.cookieService.set(this.authInfoCookie, JSON.stringify(data), 0, '/', undefined, secure, 'Strict');
  }

  get user(): User { return this._user; }
  get authenticated(): boolean { return this._user !== null; }
  get initialized(): boolean { return this._initialized; }
  get failed(): boolean { return this.user === null; }
  get errorCode(): number { return ErrorCode.httpToErrorCode(this._httpErrorCode); }
  get errorMessage(): any { return this._error?.message; }
  get sessionListener(){ return this._sessionListener; }

  get authToken(): string|null {
    if (this.cookieService.check(this.authInfoCookie)) {
      const raw = this.cookieService.get(this.authInfoCookie);
      if (raw) {
        return JSON.parse(raw).token;
      }
    }

    return null;
  }

  get socketToken() {
    if (this.cookieService.check(this.authInfoCookie)) {
      const raw = this.cookieService.get(this.authInfoCookie);
      if (raw) {
        return JSON.parse(raw).socketToken;
      }
    }

    return null;
  }
}
