<div style="display: flex; flex-direction: column; gap:16px" class="sign-in-up-component">
  <div class="sp-header">
    <h2 class="h1 text-center">Get Started Now</h2>
    <h3 class="h5 text-center sp-text-secondary">Fill the form and enjoy your analysis</h3>
  </div>
  <form [formGroup]="registerFormGroup" (keydown)="keyDownFunction($event)" class="card p-4" style="display:flex; flex-direction: column; gap:8px"  (ngSubmit)="saveUserInfo()">
    <div style="display: flex; gap:16px">
      <mat-form-field class="sp-form-field" style="flex-grow: 1" appearance="outline">
        <mat-label class="sp-input-label" >First Name</mat-label>
        <input tabindex="1" formControlName="first_name" matInput placeholder="Type your first Name..." value="" required/>
        <mat-error *ngIf="registerFormGroup.get('first_name').hasError('required')">
          Required
        </mat-error>
      </mat-form-field>
      <mat-form-field class="sp-form-field" style="flex-grow: 1" appearance="outline">
        <mat-label class="sp-input-label">Last Name</mat-label>
        <input tabindex="2" formControlName="last_name" matInput placeholder="Type your last Name..." value="" required/>
        <mat-error *ngIf="registerFormGroup.get('last_name').hasError('required')">
          Required
        </mat-error>
      </mat-form-field>
    </div>
    <mat-form-field class="sp-form-field" appearance="outline">
      <mat-label class="sp-input-label">Company Email Address</mat-label>
      <input formControlName="email" matInput placeholder="Type your company email address..." required tabindex="3" value=""/>
      <mat-error *ngIf="registerFormGroup.get('email').hasError('email')">
        Invalid email address
      </mat-error>
      <mat-error *ngIf="registerFormGroup.get('email').hasError('required')">
        Required
      </mat-error>
      <mat-error *ngIf="registerFormGroup.get('email').hasError('laravel')">
        {{ registerFormGroup.get('email').getError('laravel') }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="sp-form-field" appearance="outline">
      <mat-label class="sp-input-label">Company</mat-label>
      <input autocomplete="off" formControlName="company" matInput placeholder="Type your company name..." required tabindex="4" value=""/>
      <mat-error *ngIf="registerFormGroup.get('company').hasError('required')">
        Required
      </mat-error>
    </mat-form-field>
    <mat-form-field class="sp-form-field" style="flex-grow: 1" appearance="outline">
      <mat-label class="sp-input-label">Password</mat-label>
      <input tabindex="5" matInput [type]="hide ? 'password' : 'text'" formControlName="password" placeholder="Type your password..." value="" required/>
      <mat-error *ngIf="registerFormGroup.get('password').hasError('minlength')">
        Password must be at least 10 characters
      </mat-error>
      <mat-error *ngIf="registerFormGroup.get('password').hasError('required')">
        Required
      </mat-error>
      <mat-error *ngIf="registerFormGroup.get('password').hasError('pattern') && !registerFormGroup.get('password').hasError('minlength')">
        Password must contain 1 uppercase, 1 lowercase and 1 number
      </mat-error>
    </mat-form-field>
    <mat-form-field class="sp-form-field" style="flex-grow: 1" appearance="outline">
      <mat-label class="sp-input-label">Confirm Password</mat-label>
      <input tabindex="6" [type]="hide ? 'password' : 'text'" formControlName="passwordConfirm" matInput placeholder="Re-type your password..." value="" required/>
      <mat-error *ngIf="registerFormGroup.get('passwordConfirm').hasError('required')">
        Required
      </mat-error>
      <mat-error *ngIf="registerFormGroup.get('passwordConfirm').hasError('passwordMatch')">
        Passwords do not match
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="sp-form-field">
      <mat-label class="sp-input-label">What is your favorite language?</mat-label>
      <mat-select (selectionChange)="changeLanguage($event.value)" [value]="localeSelected" panelClass="sp-select-option-icon" translate="no">
        <mat-option *ngFor="let language of languages" [value]="language.code">{{language.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <span class="sp-checkbox sp-text-secondary">
     By clicking, I agree to receive messages from SOPRISM. You can unsubscribe at any time
    </span>
    <button type="submit" [disabled]="registerFormGroup.invalid" class="btn btn-lg btn-primary sp-btn sp-btn-lg sp-btn-primary" matTooltip="Click here to get started" matTooltipHideDelay="10">
      <span>Get Started Now</span>
    </button>
  </form>
  <div class="text-center" style="display: flex; flex-direction: column;">
    <div>No obligation. <strong>No credit card required</strong></div>
    <div class="sp-text-secondary">Do you already have an account? <a routerLink="/user/login">Click here</a></div>
  </div>
</div>

