import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgClass, NgIf, NgTemplateOutlet} from '@angular/common';



@Component({
  selector: 'app-titan-button',
  standalone: true,
  templateUrl: './titan-button.component.html',
  imports: [
    NgClass
  ],
  styleUrls: ['./titan-button.component.scss']
})
export class TitanButtonComponent {

  @Input() isDisabled: boolean = false;

  @Output() onPress = new EventEmitter<void>();
  @Input() variant: string = 'primary';
  @Input() buttonType: string = 'default';

  handleButtonClick() {
    this.onPress.emit();
  }
}
