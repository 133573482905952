import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {firstValueFrom, Observable} from 'rxjs';
import {AuthenticationService} from "../../services/sp-authentication/authentication.service";
import {Session} from "../../models/session";
import {environment} from "../../../environments/environment";
import {ErrorCode} from '../../utils/error/error-code';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthenticationService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // Returning promise in case if session has not been initialized yet
    return new Promise<boolean | UrlTree>((async resolve => {
      const authCheck = async (session: Session): Promise<boolean | UrlTree> => {
        if (session && session.authenticated) {
          return true;
        } else if (session.errorCode== ErrorCode.ERROR_CODES.ERR_NOT_FOUND) {
          await this.router.navigate(['page-unknown-user']);
        }
        else {
          await this.auth.redirectToLogin();
          return false;
        }
      };

      if(!environment.config.apiCheck) resolve(true);

      // Session check
      if (this.auth.session.initialized) {
        resolve(await authCheck(this.auth.session));
      } else {
        firstValueFrom(this.auth.sessionListener).then(async session => {
          resolve(await authCheck(session));
        });
      }
    }));
  }
}
