import { CommonModule } from '@angular/common';
import { FormsModule }   from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule} from '@angular/forms';
import { SpUserLoginComponent } from './sp-user-login/sp-user-login.component';
import { SpUserPasswordComponent } from './sp-user-password/sp-user-password.component';
import { SpUserRegisterComponent } from './sp-user-register/sp-user-register.component';
import { SpUserRoutingModule } from './sp-user-routing.module';
//import {CaptchaModule} from 'primeng/captcha';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { HttpClientModule } from '@angular/common/http';
import {RecaptchaModule} from 'ng-recaptcha';
import {TitanDivider} from '../../user-interface/titan-divider/titan-divider';


@NgModule({
  declarations: [
    SpUserLoginComponent,
    SpUserPasswordComponent,
    SpUserRegisterComponent
  ],
    exports: [
        SpUserLoginComponent,
        SpUserPasswordComponent,
        SpUserRegisterComponent
    ],
    imports: [
        SpUserRoutingModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatStepperModule,
        ReactiveFormsModule,
        MatRadioModule,
        CommonModule,
        MatIconModule,
        FormsModule,
        MessagesModule,
        MessageModule,
        HttpClientModule,
        MatTableModule,
        MatTooltipModule,
        MatButtonModule,
        MatSelectModule,
        RecaptchaModule,
        TitanDivider
    ]
})
export class SpUserModule {}
